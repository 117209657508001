import {
    Box,
    CardActions,
    CardContent,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme,
    Button,
    CircularProgress
} from "@material-ui/core";
import {ICommon, INomadeCounter, IScheduleDesktop, MeetingSchema} from "app/models/booking.model";
import React, {ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useApp} from "app/providers/app.provider";
import {useOnView} from "app/providers/onview.provider";
import Session from "app/libraries/session.lib";
import {useOnLogin} from "app/providers/onlogin.provider";
import { useNavigate } from 'react-router-dom';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {ServiceTypeEnum} from "app/enums/booking.enum";
import {ICenter} from "app/models/centers.model";
import {Skeleton} from "@material-ui/lab";

interface IProps {
    meetingRoom: MeetingSchema
    commonServices: ICommon[],
    centerIds: number[],
    targetCenter: string,
    setTargetCenter: Dispatch<SetStateAction<string>>,
    nomadeCounter?: INomadeCounter
}

const BookingReadRightComponent = ({meetingRoom, commonServices, centerIds, targetCenter, setTargetCenter, nomadeCounter}: IProps) => {
    const {
        bookingHours,
        bookingDate,
        bookingDateMinAndMax,
        bookingHour,
        setBookingDate,
        setBookingHour,
    } = useApp()
    const theme = useTheme()
    const {t} = useTranslation()
    const priceStyles = useMemo(() => {
        return {
            background: theme.palette.primary.main,
            height: 80,
            alignItems: 'center',
            display: 'flex',
            paddingLeft: 20,
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderLeft: '1px solid',
        }
    }, [theme])
    const [isValidDate, setIsValidDate] = useState<boolean>(true)
    const minDate = useMemo(() => bookingDateMinAndMax.min, [bookingDateMinAndMax.min])
    const maxDate = useMemo(() => bookingDateMinAndMax.max(), [bookingDateMinAndMax.max])
    const [endHours, setEndHours] = useState<string[]>([])
    const contractFound = useRef(true)
    const {
        pricingCustomerReservationUseMutation,
        availabilityMeetingRoomUseMutation,
        customerReservationCreateUseMutation,
        listScheduleDesktopUseMutation
    } = useOnView()
    const {currentEnterprise, centers} = useOnLogin()
    const [price, setPrice] = useState<number>(0)
    const [commonServicesIds, setCommonServicesIds] = useState<number[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const isCommon = useRef(false)
    const isPerson = useRef(false)
    const [nbPerson, setNbPerson] = useState<number>(1)
    const [beginHours, setAvailableBeginHours] = useState<number[]>(bookingHours.map(x => Number(x)));
    const commonQuantity = useMemo(() => {
        const num = parseInt(meetingRoom.capacity ?? "0", 10)
        return Array.from({length: num}, (_, index) => index + 1)
    }, [meetingRoom.capacity])
    const navigate = useNavigate()
    const {fromApp} = useApp()
    const [message, setMessage] = useState<string>('');
    const isMeeting = useMemo(() => meetingRoom.serviceType === String(ServiceTypeEnum.MEETING), [meetingRoom])
    const bookingListLink = useMemo<string>(
        () => fromApp ? '/booking_list?fromApp=1' : '/booking_list',
        [fromApp])

    const ItemPrice = useCallback(({price, label, style}: { price: string, label: string, style?: {} }) => {
        return (
            <Grid item xs={4} style={{...priceStyles, ...style}}>
                <Box display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} flexDirection={'row'}>
                        <Typography style={{color: 'white', marginRight: 5}} variant={'h2'}>{price}€</Typography>
                        <Typography style={{color: 'white'}} variant={'subtitle2'}><sup>HT</sup></Typography>
                    </Box>
                    <Typography style={{color: 'white'}} variant={'subtitle2'}>{t(label)}</Typography>
                </Box>
            </Grid>
        )
    }, [priceStyles, t])

    const ItemCommon = useCallback(({service, commonIds}: { service: ICommon, commonIds: number[] }) => {
        return (
            <>
                <Grid item xs={8}>
                    <FormControlLabel control={<Checkbox color="primary" size="small" onChange={() => {
                        isCommon.current = true
                        if (commonIds.includes(service.id)) {
                            setCommonServicesIds(prevState => prevState.filter(id => id !== service.id))
                        } else {
                            setCommonServicesIds(prevState => [...prevState, service.id])
                        }
                    }}/>}
                                      label={service.label}/>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" style={{textAlign: 'end'}}>
                        {service.price}€<sup>HT</sup>
                    </Typography>
                </Grid>
            </>
        )
    }, [setCommonServicesIds])

    const handleDateChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        contractFound.current = true
        const date = event.target.value
        setIsValidDate(date !== '')
        setBookingDate(date)
    }, [setBookingDate, setIsValidDate])

    useEffect(() => {
        if (bookingHour.begin !== '0') {
            const beginIndex = bookingHours.indexOf(bookingHour.begin)
            if (beginIndex !== -1) {
                let newEndHours = bookingHours.slice(beginIndex + 1)
                newEndHours.push('18')
                setEndHours(newEndHours)
            }
        } else {
            setEndHours([])
        }
    }, [bookingHour.begin, bookingHours])

    const handleSubmit = useCallback(async () => {
        const data = {
            individualId: Session.getUser()!.individual_id,
            serviceType: Number(meetingRoom.serviceType),
            nbPerson: nbPerson,
            center: isMeeting ? Number(meetingRoom.centerId) : Number(targetCenter),
            begin: `${bookingDate}T${bookingHour.begin}:00:00`,
            end : `${bookingDate}T${bookingHour.end}:00:00`,
            commonServices: commonServicesIds,
            service: Number(meetingRoom.id),
            message: message
        }
        await customerReservationCreateUseMutation?.mutateAsync(data)
        navigate(bookingListLink)
    }, [
        Session,
        meetingRoom,
        customerReservationCreateUseMutation,
        nbPerson,
        bookingDate,
        bookingHour,
        commonServicesIds,
        history,
        isMeeting,
        bookingListLink,
        targetCenter
    ])

    const getPricer = useCallback(async () => {
        const data = {
            individualId: Session.getUser()!.individual_id,
            center: isMeeting ? Number(meetingRoom.centerId) : Number(targetCenter),
            service: Number(meetingRoom.id),
            nbPerson: nbPerson,
            begin: `${bookingDate}T${bookingHour.begin}:00:00`,
            end : `${bookingDate}T${bookingHour.end}:00:00`,
            commonServices: commonServicesIds,
            serviceType: Number(meetingRoom.serviceType)
        }
        setIsLoading(true)
        let response = await pricingCustomerReservationUseMutation?.mutateAsync({
            enterpriseId: currentEnterprise,
            body: data
        })
        setPrice(Number(response?.total))
        setIsLoading(false)
    }, [
        Session,
        meetingRoom,
        bookingDate,
        bookingHour,
        commonServicesIds,
        pricingCustomerReservationUseMutation,
        currentEnterprise,
        setIsLoading,
        nbPerson,
        targetCenter,
        isMeeting
    ])


    const getAvailability = useCallback(async (data) => {
        setIsLoading(true)
        let found = false;
        if (isMeeting) {
            let response = await availabilityMeetingRoomUseMutation?.mutateAsync({
                id: meetingRoom.id,
                queryParams: data
            })
            setIsLoading(false)
            found = response!.found
        }
        contractFound.current = found
        if (!found) {
            getPricer().then()
        }
    }, [
        meetingRoom,
        availabilityMeetingRoomUseMutation,
        getPricer,
        setIsLoading,
        isMeeting
    ])

    const getScheduleList = useCallback(async () => {
        if (meetingRoom !== undefined && isMeeting) {
            let schedules = await listScheduleDesktopUseMutation?.mutateAsync({
                service: Number(meetingRoom.id),
                date: bookingDate,
                enterpriseId: currentEnterprise
            })
            if (undefined !== schedules) {
                let beginHoursTmp: number[] = []
                schedules.map((schedule: IScheduleDesktop) => {
                    let end = Number(schedule.end) + 1
                    for (let i = Number(schedule.begin); i < end; ++i) {
                        if (!beginHoursTmp.includes(i)) {
                            beginHoursTmp.push(Number(i))
                        }
                    }
                })
                setAvailableBeginHours(bookingHours.map((x) => Number(x)).filter((x) => !beginHoursTmp.includes(x)))
            }
        }

        if(!isMeeting) {
            const isToday = bookingDate === new Date().toISOString().split('T')[0]
            if (isToday) {
                const now = new Date()
                const currentHour = now.getHours()
                const newBeginHours = bookingHours
                  .map((x) => Number(x))
                  .filter((x) => x > currentHour)
                setAvailableBeginHours(newBeginHours)
            }
            if(!isToday) setAvailableBeginHours(bookingHours.map((x) => Number(x)))
        }
    }, [bookingDate, meetingRoom, currentEnterprise, isMeeting])

    useEffect(() => {
        getScheduleList().then()
    }, [getScheduleList]);

    useEffect(() => {
        let data = {}
        if (bookingDate && bookingHour.begin !== '0' && bookingHour.end !== '0' && isValidDate) {
            data = {
                begin: `${bookingDate}T${bookingHour.begin}:00:00`,
                end : `${bookingDate}T${String(Number(bookingHour.end) - 1).padStart(2, '0')}:59:59`,
            }
            if (isCommon.current || isPerson.current) {
                if (!contractFound.current) {
                    getPricer().then()
                }
            } else {
                getAvailability(data).then()
            }
        }

        isCommon.current = false
        isPerson.current = false
    }, [
        bookingDate,
        bookingHour,
        isValidDate,
        commonServicesIds,
        contractFound,
        nbPerson,
        targetCenter,
    ])

    return (
        <Grid item xs={12} sm={5}>
            <CardContent style={{backgroundColor: theme.palette.background.default, padding: 0, height: '100%'}}>
                {isMeeting &&
                    <Grid container style={{marginBottom: 15}}>
                        <ItemPrice
                            price={String(meetingRoom?.hourly_price)}
                            label={'common.global.hour'}
                        />
                        <ItemPrice
                            price={String(meetingRoom?.halfday_price)}
                            label={'common.global.halfDay'}
                        />
                        <ItemPrice
                            price={String(meetingRoom?.daily_price)}
                            label={'common.booking_select_day'}
                            style={{borderRight: '1px solid'}}
                        />
                    </Grid>}
                <Box padding={2}>
                    {!isMeeting && <Box marginBottom={3}>
                        <Typography variant="subtitle2" gutterBottom>
                            {t('common.global.center')}
                        </Typography>
                        <FormControl variant="outlined" fullWidth size={'small'}>
                            <Select
                                labelId="center-filter"
                                value={centerIds.includes(Number(targetCenter)) ? targetCenter : '0'}
                                onChange={(event) => setTargetCenter(String(event.target.value))}
                            >
                                {centers !== undefined && centers.map((center: ICenter) => (
                                    <MenuItem key={center.id} value={center.id}>{center.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Box>}
                    <Typography variant="subtitle2" gutterBottom>
                        {t('common.bookingView.bookingReadRightComponent.timeSlot')}
                    </Typography>
                    <Grid container spacing={5} alignItems={'flex-end'} style={{marginBottom: 15, marginTop: 2}}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id={'begin-date'}
                                variant="outlined"
                                label={t('common.begin')}
                                type="date"
                                size="small"
                                value={bookingDate}
                                onChange={handleDateChange}
                                inputProps={{
                                    min: minDate,
                                    max: maxDate
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                error={!isValidDate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size={'small'} variant="outlined" fullWidth>
                                <InputLabel id="begin-hours">{t('common.booking_schedule')}</InputLabel>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    labelId="begin-hours"
                                    value={bookingHour.begin}
                                    onChange={(event) => {
                                        contractFound.current = true
                                        setBookingHour(prevState => ({
                                            ...prevState,
                                            begin: String(event.target.value),
                                            end: '0'
                                        }))
                                    }}
                                    label={t('common.booking_schedule')}
                                >
                                    <MenuItem value="0">
                                        <em>{t('common.booking_select_hour_start')}</em>
                                    </MenuItem>
                                    {bookingHours.map((hour: string) => (
                                        <MenuItem disabled={!beginHours.includes(Number(hour))} key={hour}
                                                  value={hour}>{`${hour}h`}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id={'end-date'}
                                label={t('common.end')}
                                type="date"
                                size="small"
                                value={bookingDate}
                                variant="outlined"
                                onChange={handleDateChange}
                                inputProps={{
                                    min: minDate,
                                    max: maxDate,
                                    readOnly: true
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                error={!isValidDate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size={'small'} variant="outlined"
                                         fullWidth>
                                <InputLabel id="end-hours">{t('common.booking_schedule')}</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="end-hours"
                                    value={bookingHour.end}
                                    onChange={(event) => {
                                        contractFound.current = true
                                        setBookingHour(prevState => ({
                                            ...prevState,
                                            end: String(event.target.value)
                                        }))
                                    }}
                                    label={t('common.booking_schedule')}
                                >
                                    <MenuItem value="0">
                                        <em>{t('common.booking_select_hour_end')}</em>
                                    </MenuItem>
                                    {endHours.map((hour: string) => (
                                        <MenuItem key={hour} value={hour}>{`${hour}h`}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {isMeeting && <Box marginBottom={3}>
                        <Typography variant="subtitle2" gutterBottom>
                            {t('common.booking_select_number_of_person')}
                        </Typography>
                        <FormControl size={'small'} fullWidth>
                            <Select
                                variant={'outlined'}
                                value={nbPerson}
                                onChange={(event) => {
                                    isPerson.current = true
                                    setNbPerson(Number(event.target.value))
                                }}
                            >
                                {commonQuantity.map((quantity, index) => (
                                    <MenuItem key={index} value={quantity}>{quantity}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Box>}
                    {isMeeting && <>
                        <Typography variant="subtitle2" gutterBottom>
                            {t('common.additional_needs')}
                        </Typography>
                        <Grid container alignItems={'center'} style={{marginBottom: 15}}>
                            {commonServices !== undefined && commonServices.map((service: ICommon) =>
                                <ItemCommon key={service.id} service={service} commonIds={commonServicesIds}/>
                            )}
                        </Grid>
                    </>}
                    {!isMeeting && <Box>
                        <Typography variant="subtitle2" gutterBottom>
                            {t('common.additional_needs')}
                        </Typography>
                        <Grid container alignItems={'center'} style={{marginBottom: 15}}>
                            <TextField
                                fullWidth
                                size={'small'}
                                multiline
                                minRows={4}
                                variant={'outlined'}
                                value={message}
                                onChange={(event) => {
                                    setMessage(event.target.value)
                                }}
                            />
                        </Grid>
                    </Box>}

                    {nomadeCounter !== undefined && nomadeCounter.countReservation !== undefined && (
                        <>
                            {nomadeCounter.maxFreeReservation !== null && nomadeCounter.countReservation < nomadeCounter.maxFreeReservation && (
                                <Grid item xs={12} md={12}>
                                    <Box component={'p'} className="bg-info" px={1} py={1} borderRadius={4}>
                                        {t('common.booking_nomade_counter', {
                                            countReservation: nomadeCounter.countReservation,
                                            maxFreeReservation: nomadeCounter.maxFreeReservation,
                                            nomadeLabel: nomadeCounter.nomadeLabel
                                        })}
                                    </Box>
                                </Grid>
                            )}
                            {nomadeCounter.maxFreeReservation === null && (
                                <Grid item xs={12} md={12}>
                                    <Box component={'p'} className="bg-info" px={1} py={1} borderRadius={4}>
                                        {t('common.booking_nomade_200_counter', {
                                            countReservation: nomadeCounter.countReservation,
                                            nomadeLabel: nomadeCounter.nomadeLabel
                                        })}
                                    </Box>
                                </Grid>
                            )}
                        </>
                    )}
                    <Divider style={{marginBottom: 15}}/>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant="h6" gutterBottom>
                            {t('common.global.total')}
                        </Typography>
                        <Typography variant="h6" gutterBottom color={'primary'}>
                            {!isLoading && bookingHour.end != '0' && bookingHour.begin != '0' && <>{price} €<sup>TTC</sup></>}
                            {isLoading && <Skeleton width={'36px'}/>}
                        </Typography>
                    </Box>
                    <CardActions style={{display: 'flex', justifyContent: 'center'}}>
                        <Button
                            disabled={contractFound.current || isLoading}
                            variant='contained'
                            type="button"
                            color="primary"
                            style={{width: 200, minHeight: 37}}
                            onClick={handleSubmit}
                        >
                            {isLoading ? (<CircularProgress className="small-loader"/>) : t('common.global.toBook')}
                        </Button>
                    </CardActions>
                </Box>
            </CardContent>
        </Grid>
    )
}

export default BookingReadRightComponent
